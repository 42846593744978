import React, { useEffect, useState } from 'react';
import { NextApiRequest, NextApiResponse } from 'next';
import App from 'next/app';
import type { AppProps, AppContext } from 'next/app';
import { useRouter } from 'next/router';
import Cors from 'cors';
import NProgress from 'nprogress';
import { Entry } from 'contentful';
import { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import 'simplebar/dist/simplebar.min.css';
import '../styles/global.css';
import '../styles/nprogress.scss';
import Cookies from 'cookies';
import cookie from 'cookie';
import { getErrorBoundary, bugsnagStart } from '../util/bugsnag';
import initMiddleware from '../util/initMiddleware';
import { getEntries } from '../contentful/client';
import { CountryType, LayoutType, PageType } from '../util/contentfulTypes';
import CookiesNotificationContentful from '../components/CookiesNotification/CookiesNotificationContentful';
import GtmBody from '../components/GtmBody/GtmBody';
import LayoutWrapper from '../components/LayoutWrapper/LayoutWrapper';
import getGeolocation from '../util/getGeolocation';
import { GeolocationType } from '../util/types';
import setTargetCookies from '../util/setTargetCookies';
import useWindowWidth from '../hooks/useWindowWidth';
import Event from '@bugsnag/core/types/event';


const cors = initMiddleware(
  Cors({
    methods: ['GET', 'POST', 'OPTIONS'],
    origin: '*',
    exposedHeaders: '*',
    credentials: true,
    optionsSuccessStatus: 200,
  }),
);

interface MainAppProps extends AppProps {
  bugsnagApiKey?: string,
  baseURL: string,
  layout?: Entry<LayoutType>,
  gtmTag?: string,
  pages?: Entry<PageType>[],
  cookiesAccepted?: boolean,
  location?: CountryType,
  preview?: boolean,
  hiddenNotifications?: Record<string, boolean>,
}

const getPath = (path: string) => {
  const queryIndex = path.indexOf('?');
  if (queryIndex === -1) {
    return path;
  }
  return path.slice(0, queryIndex);
};

export default function MainApp({
  Component,
  pageProps,
  bugsnagApiKey,
  baseURL,
  layout,
  gtmTag,
  pages,
  cookiesAccepted,
  location,
  preview,
  hiddenNotifications,
}: MainAppProps) {
  const router = useRouter();
  const windowWidth = useWindowWidth(1100);
  const [active, setActive] = useState(router.asPath || '/');
  const [currentPage, setCurrentPage] = useState(
    pages?.find((page: Entry<PageType>) => page.fields.slug === getPath(router.asPath || '/')),
  );
  const [disableNotification, setDisableNotification] = useState(
    layout?.fields?.notification?.sys.id && hiddenNotifications ?
      !!Object.keys(hiddenNotifications).find(key => key.includes(layout?.fields?.notification?.sys.id || ' ')) : false);

  let ErrorBoundary: BugsnagErrorBoundary | null | undefined = getErrorBoundary();

  async function registerServiceWorker() {
    navigator.serviceWorker.register('/sw.js');
  }

  async function stopNprogress() {
    setTimeout(() => {
      NProgress.done();
    }, 200);
  }

  function onError(event: Event, cb: (err: null | Error, shouldSend?: boolean) => void) {
    console.log(event)
    console.log(cb)
  }

  useEffect(() => {
    const handleStart = (newPath: string) => {
      if (newPath !== active && windowWidth > 1050) {
        NProgress.start();
      }
    };

    if (document) {
      NProgress.configure({
        easing: 'ease',
        speed: 500,
      });
      router.events.on('routeChangeStart', handleStart);
    }

    if (window) {
      if (window.location.hostname !== 'localhost') {
        window.addEventListener('load', registerServiceWorker);
      }
    }

    bugsnagStart(bugsnagApiKey);
    ErrorBoundary = getErrorBoundary();

    return () => {
      navigator.sendBeacon('/api/clean-cookies');
      if ('serviceWorker' in navigator) {
        window.removeEventListener('load', registerServiceWorker);
      }
      router.events.off('routeChangeStart', handleStart);
    };
  }, []);

  useEffect(() => {
    const newPath = getPath(router.asPath);
    setActive(newPath);
    setCurrentPage(pages?.find((page: Entry<PageType>) => page.fields.slug === newPath));
    stopNprogress();
  }, [router.query.slug]);

  if (preview) {
    return (
      <div style={{ minHeight: '100vh' }}>
        <Component {...pageProps} />
      </div>
    );
  }

  const content = (
    <div style={{ minHeight: '100vh' }}>
      <GtmBody gtmTag={gtmTag} />
      <LayoutWrapper
        currentPage={currentPage}
        layout={layout}
        active={active}
        location={location}
        disableNotification={disableNotification}
        setDisableNotification={() => setDisableNotification(true)}
      >
        <Component
          {...pageProps}
          page={currentPage}
          withNotification={!disableNotification && !!layout?.fields?.notification?.fields}
          baseURL={baseURL}
          pathname={getPath(active)}
          locale={location?.locale || 'en-US'}
        />
      </LayoutWrapper>
      <CookiesNotificationContentful
        cookiesNotification={layout?.fields?.cookiesNotification}
        cookiesAccepted={cookiesAccepted}
      />
    </div>
  );

  //todo: FallbackComponent
  if (ErrorBoundary) {
    return (
      <ErrorBoundary onError={onError}>
        {content}
      </ErrorBoundary>
    );
  }

  return content;

}


MainApp.getInitialProps = async (appContext: AppContext) => {
  const appProps: AppProps = await App.getInitialProps(appContext) as AppProps;
  const { res, req } = appContext.ctx;

  const envProps = {
    bugsnagApiKey: process.env.BUGSNAG_API_KEY,
    baseURL: process.env.SITE_URL,
    gtmTag: process.env.GTM_TAG,
  };

  if (process.env.BUGSNAG_API_KEY) {
    bugsnagStart(process.env.BUGSNAG_API_KEY);
  }

  const cookies = req && res ? new Cookies(req, res) : undefined;
  const parsedCookies = cookie.parse(req ? req.headers.cookie || '' : document.cookie);
  const hiddenNotifications = Object.keys(parsedCookies)
    .filter(key => key.startsWith('adapt_hide_notification_'))
    .reduce((previous, current) => ({ ...previous, [current]: parsedCookies[current] === 'true' }), {});

  await cors(appContext.ctx.req as NextApiRequest, appContext.ctx.res as NextApiResponse);

  if (req?.url === '/sitemap.xml' || req?.url === '/robots.txt') {
    return {
      ...appProps,
    };
  }

  if (req?.url?.includes('storybook-previews') && process.env.CONTENTFUL_HOST === 'preview.contentful.com') {
    return {
      ...appProps,
      layout: {},
      preview: true,
      hiddenNotifications,
      ...envProps,
    };
  }

  const defaultLocales: Entry<{
    apac: 'en-US',
    emea: 'en-US',
    latam: 'en-US',
    na: 'en-US'
  }>[] | undefined = await getEntries('fallbackLocales', 'en-US');

  const targets: GeolocationType = await getGeolocation({
    req,
    res,
    cookies: parsedCookies,
    fallbacks: defaultLocales?.[0]?.fields,
  });

  await setTargetCookies({ targets, cookies });

  const pages = await getEntries('page', targets.locale);
  const layout = await getEntries('layout', targets.locale);

  if (pages && pages?.length > 0 && req?.url) {
    const page = pages.find((page: Entry<PageType>) => page.fields.slug === getPath(req?.url || '/not-found'));

    if (!page && !(req.url.includes('/not-found'))) {
      // console.log(pages)
      // return {
      //   redirect: {
      //     destination: '/',
      //     permanent: false,
      //   },
      // }
      if (typeof window === 'undefined' && res && !res.writableEnded) {
        res.writeHead(307, { Location: '/' });
        res.end();
        return {};
      }
    }

    if (!!page) {
      return {
        ...appProps,
        layout: layout?.[0] || {},
        pages,
        cookiesAccepted: parsedCookies._adapt_cookies_accepted === 'true' || false,
        location: { code: targets.country, locale: targets.locale },
        hiddenNotifications,
        ...envProps,
      };
    }
  }

  return {
    ...appProps,
    cookiesAccepted: parsedCookies._adapt_cookies_accepted === 'true' || false,
    location: { code: targets.country, locale: targets.locale },
    hiddenNotifications,
    ...envProps,
  };
};
