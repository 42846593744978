import React, { useContext, useEffect, useState } from 'react';
import cx from 'clsx';
import Container from '../Container/Container';
import SimpleButton from '../SimpleButton/SimpleButton';
import { useRouter } from 'next/router';
import BurgerIcon from '../icons/BurgerIcon/BurgerIcon';
import MobileMenu from './MobileMenu/MobileMenu';
import { ButtonType } from '../../util/contentfulTypes';
import classes from './Navigation.module.scss';

export interface NavigationProps extends React.HTMLProps<HTMLDivElement> {
  className?: string,
  buttons?: ButtonType[],
  logo?: string,
  darkLogo?: string,
  active?: '/' | '/about' | '/how-it-works' | '/stories' | '/faq' | '/application' | string,
  notificationHeight?: number,
  disableNotification?: boolean,
  relativePosition?: boolean,
  scrolled?: boolean,
}

export default function Navigation({
  className,
  buttons,
  logo,
  darkLogo,
  active,
  notificationHeight = 0,
  disableNotification,
  style,
  relativePosition,
  scrolled: scrolledState = false,
  ...others
}: NavigationProps) {
  const [innerWidth, setInnerWidth] = useState(1100);
  const [currentPath, setCurrentPath] = useState<string>(active || '/');
  const [scrolled, setScrolled] = useState(scrolledState || false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const router = useRouter();

  const handleResize = () => {
    setInnerWidth(window.innerWidth);
    setShowMobileMenu(false);
  };

  const handleScroll = () => {
    if (scrolledState) {
      setScrolled(true);
    } else {
      setScrolled(window.scrollY > 100);
    }
  };

  const onNavigationClick = (href: string) => {
    const questionIndex = router.asPath.indexOf('?');
    if (router.asPath.slice(0, questionIndex == -1 ? router.asPath.length : questionIndex) !== href) {
      router.push({
        pathname: '/',
        query: {
          slug: href.slice(1, href.length) || '',  // update the query param
        },
      }, href, { shallow: true });
    }
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (window) {
      setInnerWidth(window.innerWidth);
      setScrolled(scrolledState || document.documentElement.scrollTop !== 0);
      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    if (router) {
      setCurrentPath(router.pathname || '');
    } else {
      if (active && active !== currentPath) {

        setCurrentPath(active);
      }
    }
  }, [active, router]);

  return (
    <nav
      className={cx(classes.navigation, className, {
        //@ts-ignore
        [classes.scrolled]: others.storyScrolled || scrolled,
        [classes.relativePosition]: relativePosition,
      })}
      style={{ ...style, top: !disableNotification ? notificationHeight : 0 }}
      {...others}
    >
      <Container size="large" className={classes.content}>
        <div className={classes.logoColumn}>
          <SimpleButton href={'/'} component="a" className={classes.logoButton}>
            <div className={classes.logos}>
              <div className={cx(classes.logo, classes.lightLogo)}>
                <img src={logo} alt="logo" />
              </div>
              <div className={cx(classes.logo, classes.darkLogo)}>
                <img src={darkLogo} alt="logo" />
              </div>
            </div>
          </SimpleButton>
        </div>
        {innerWidth > 1050 ? (
          <div className={classes.menuButtons}>
            {buttons?.map((button, index) => {
              const { secondary, footerTrackingKey, href, ...rest } = button;

              return (
                <SimpleButton
                  key={index}
                  component="button"
                  className={cx(
                    classes.menuButton,
                    {
                      // [classes.active]: currentPath === button.href,
                      [classes.application]: secondary || href.includes('application'),
                    })}
                  onClick={() => onNavigationClick(href)}
                  {...rest}
                />
              );
            })}
          </div>
        ) : (
          <div className={classes.burgerIcon}>
            <SimpleButton
              onClick={(e) => {
                e?.preventDefault();
                setShowMobileMenu(!showMobileMenu);
              }}
              ariaLabel="Menu button"
            >
              <BurgerIcon opened={showMobileMenu} />
            </SimpleButton>
          </div>
        )}
      </Container>
      <MobileMenu
        buttons={buttons}
        currentPath={currentPath}
        showMenu={showMobileMenu}
        toggleMenu={setShowMobileMenu}
        onNavigationClick={onNavigationClick}
      />
    </nav>
  );
}
