import React from 'react';
import { Entry } from 'contentful';
import { ButtonType, CookiesNotificationType } from '../../util/contentfulTypes';
import CookiesNotification from './CookiesNotification';

export default function CookiesNotificationContentful({
  cookiesNotification,
  cookiesAccepted,
}: {
  cookiesNotification?: Entry<CookiesNotificationType>,
  cookiesAccepted?: boolean
}) {
  if (!cookiesNotification) {
    return null;
  }

  return (
    <CookiesNotification
      description={cookiesNotification?.fields?.description}
      buttons={cookiesNotification?.fields?.buttons?.map(
        (button: Entry<ButtonType>) => button.fields,
      )}
      acceptButton={cookiesNotification?.fields?.acceptButton?.fields}
      cookiesAccepted={cookiesAccepted}
    />
  );
}
