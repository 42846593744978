import React from 'react';
import IconInterface from './iconInterface';

const directionsMap = {
  right: 0,
  left: 180,
  top: -90,
  bottom: 90,
}

export default function ChevronIcon({
  size = 54,
  direction = 'right',
  style,
  ...others
}: { direction?: 'top' | 'left' | 'bottom' | 'right' } & IconInterface) {
  return (
    <svg
      fill="none"
      viewBox="0 0 14 21"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      style={{
        ...style,
        transform: `rotate(${directionsMap[direction]}deg)`,
      }}
      {...others}
    >
      <path
        d="M13.346 9.684L3.446.338a1.275 1.275 0 00-1.73 0L.563 1.428a1.109 1.109 0 00-.002 1.63L8.406 10.5.56 17.942a1.109 1.109 0 00.002 1.63l1.155 1.09c.477.45 1.252.45 1.729 0l9.9-9.346a1.11 1.11 0 000-1.632z"
        fill="currentColor"
      />
    </svg>
  );
}

