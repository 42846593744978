import React from 'react';
import cx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import SimpleButton from '../../SimpleButton/SimpleButton';
import { ButtonType } from '../../../util/contentfulTypes';
import classes from './MobileMenu.module.scss';

export interface MobileMenuProps extends React.HTMLProps<HTMLDivElement> {
  className?: string,
  buttons?: ButtonType[],
  currentPath?: string,
  showMenu: boolean,

  toggleMenu(state: boolean): void;

  onNavigationClick(href: string): void;
}

export default function MobileMenu({
  className,
  buttons,
  currentPath,
  showMenu,
  toggleMenu,
  onNavigationClick,
  ...others
}: MobileMenuProps) {
  return (
    <CSSTransition
      in={showMenu}
      timeout={200}
      classNames={classes}
      unmountOnExit
    >
      <div className={cx(classes.mobileMenu, className)} {...others}>
        <div className={classes.menuButtons}>
          {buttons?.map((button, index) => {
            const { secondary, href, ...rest } = button;
            return (
              <SimpleButton
                key={index}
                component="button"
                className={cx(
                  classes.menuButton,
                  {
                    [classes.application]: secondary || href.includes('application'),
                  })}
                onClick={(e) => {
                  e?.preventDefault();
                  onNavigationClick(href);
                  toggleMenu(false);
                }}
                {...rest}
              />
            );
          })}
        </div>
      </div>
    </CSSTransition>
  );
}
