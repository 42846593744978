import React, { CSSProperties } from 'react';
import Link from 'next/link';
import cx from 'clsx';
import classes from './SimpleButton.module.scss';

export interface SimpleButtonProps<T> extends Omit<React.HTMLProps<T>, 'onClick'> {
  type?: 'button' | 'submit' | 'reset';
  component?: React.ComponentType<T> | React.ElementType,
  style?: CSSProperties;
  ariaLabel?: string;
  href?: string;
  disabled?: boolean;
  trackingKey?: string;
  footerTrackingKey?: string,

  onClick?(arg0?: React.MouseEvent | React.TouchEvent): void,
}

export default function SimpleButton<T = 'a'>({
  className,
  component,
  href,
  onClick,
  ariaLabel,
  style,
  children,
  type,
  disabled,
  trackingKey,
  footerTrackingKey,
  ...others
}: SimpleButtonProps<T>) {
  const Component = component || 'a';

  return Component === 'a' ? (
    <Link href={href || '/'} as={href} shallow>
      {/*@ts-ignore*/}
      <Component
        data-button
        role="button"
        onClick={(e: React.MouseEvent | React.TouchEvent) => {
          if (onClick && !disabled) {
            onClick(e);
          }
        }}
        className={cx(classes.button, className)}
        aria-label={ariaLabel || typeof children === 'string' ? children as string : href}
        style={typeof style === 'string' ? JSON.parse(style) : style}
        {...(trackingKey ? { 'data-tracking-key': trackingKey } : {})}
        {...others}
      >
        {children}
      </Component>
    </Link>
  ) : (
    <Component
      data-button
      role="button"
      className={cx(
        classes.button, className)}
      type={type || 'button'}
      disabled={disabled}
      aria-label={ariaLabel || typeof children === 'string' ? children as string : 'button'}
      style={typeof style === 'string' ? JSON.parse(style) : style}
      {...(trackingKey ? { 'data-tracking-key': trackingKey } : {})}
      onClick={(e: React.MouseEvent | React.TouchEvent) => {
        if (onClick && !disabled) {
          onClick(e);
        }
      }}
      {...others}
    >
      {children}
    </Component>
  );

}
