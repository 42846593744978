import React from 'react';
import { Entry } from 'contentful';
import { ButtonType, NavigationType } from '../../util/contentfulTypes';
import Navigation from './Navigation';

export default function NavigationContentful({
  navigation,
  active,
  scrolled,
}: { navigation?: Entry<NavigationType>, active?: string, scrolled?: boolean }) {
  if (!navigation) {
    return null;
  }

  return (
    <Navigation
      {...(navigation?.fields || {})}
      logo={navigation?.fields?.logo?.fields?.file?.url}
      darkLogo={navigation?.fields?.darkLogo?.fields?.file?.url}
      buttons={navigation?.fields?.buttons?.map((button: Entry<ButtonType>) => (
        button.fields
      ))}
      active={active}
      scrolled={scrolled}
    />
  );
}
