import React  from 'react';
import useModal from '../../hooks/useModal';
import Modal from './Modal';

type ModalContextType = {
  modalContent?: React.ReactNode,
  handleModal(modalContent: React.ReactNode | string, width?: number): void,
  modal?: React.ReactNode,
  modalWidth?: number,
}

const ModalContext = React.createContext({} as ModalContextType);

const ModalProvider = ({ children }: { children?: React.ReactNode }) => {
  let { modal, handleModal, modalContent, modalWidth } = useModal();

  return (
    <ModalContext.Provider value={{ modal, handleModal, modalContent, modalWidth }}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
