import PlayIcon from './Play';
import ChevronIcon from './Chevron';
import QuoteEndIcon from './QuoteEnd';
import QuoteStartIcon from './QuoteStart';
import IconInterface from './iconInterface';

const iconMap = {
  play: PlayIcon,
  chevronLeft: (props: IconInterface) => <ChevronIcon direction="left" {...props} />,
  chevronRight: (props: IconInterface) => <ChevronIcon direction="right" {...props} />,
  chevronUp: (props: IconInterface) => <ChevronIcon direction="top" {...props} />,
  chevronDown: (props: IconInterface) => <ChevronIcon direction="bottom" {...props} />,
  quoteEnd: QuoteEndIcon,
  quoteStart: QuoteStartIcon,
};

export default iconMap;
