import Cookies from 'cookies';
import { GeolocationType } from './types';

interface TargetCookies {
  targets: GeolocationType,
  cookies?: Cookies,
}

export default async function setTargetCookies({
  targets,
  cookies
}: TargetCookies) {
  if (cookies) {
    try {
      Object.keys(targets).forEach((key) =>
        cookies.set(key, targets[key as keyof GeolocationType]?.toString(), { httpOnly: false }))
      return { success: true };
    } catch (error) {
      return {
        success: false,
        error,
      };
    }
  }
}
