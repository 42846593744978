export function decodeHTMLEntities(text: string) {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}

export default function fixRichText(string: string, isHTML?: boolean) {
  const fixed = string
    .replace('&gt;', '>')
    .replace('&lt;', '<')
    .replace('&#39;', '\'')
    .replace('&quot;', '"');
  return isHTML ? encodeURI(fixed) : fixed;
}


// Space ( ) is &#32
// Exclamation mark (!) is &#33
// Double quotation (“) is &#34
// Number sign (#) is &#35
// Percent sign (%) is &#37
// Comma (,) is &#44
// Hyphen (-) is &#45
// Period (.) is &#46
// Slash (/) is &#47
