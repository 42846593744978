import React, { useState } from 'react';

export default function useModal() {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState(null as React.ReactNode);
  const [width, setWidth] = useState(600);

  function handleModal(content: React.ReactNode = null, width?: number) {
    setModal(!!content);
    setWidth(width || 600)
    if (!content) {
      setTimeout(() => setModalContent(content), 300)
    } else {
      setModalContent(content);
    }
  }

  return { modal, handleModal, modalContent, modalWidth: width };
};
