import React, { useContext, useEffect, useState } from 'react';
import cx from 'clsx';
import classes from './NavigationContainer.module.scss';

interface NavigationContainerProps extends React.HTMLProps<HTMLDivElement> {
  withHero?: boolean,
}

export default function NavigationContainer({
  className,
  children,
  withHero,
  ...others
}: NavigationContainerProps) {
  return withHero ? (
    <div className={cx(classes.navigationContainer, className)} {...others}>
      {children}
    </div>
  ) : (
    <div className={cx(classes.relative, className)} {...others}>
      <div className={cx(classes.navigationContainer, className)} {...others}>
        {children}
      </div>
      <div className={classes.invisible}>
        {children}
      </div>
    </div>
  );
}
