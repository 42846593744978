import { useEffect, useState } from 'react';

export default function useWindowWidth(initialWidth: number) {
  const [windowWidth, setWindowWidth] = useState<number>(initialWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}
