import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import cx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import useClickOutside from '../../hooks/useClickOutside';
import { X } from 'react-feather';
import SimpleButton from '../SimpleButton/SimpleButton';
import classes from './Modal.module.scss';
import { ModalContext } from './ModalContext';

interface ModalProps extends React.HTMLProps<HTMLDivElement> {
  opened?: boolean,
  // isVideo?: boolean,
  // onClose?: Function,
  // children?: React.ReactNode,
  // title?: string,
  // width?: number,
  // disableCloseButton?: boolean,
}

export default function Modal({
  className,
  opened,
  ...others
}: ModalProps) {
  const { modalContent, handleModal, modal, modalWidth } = React.useContext(ModalContext);
  const innerRef = useRef(null);


  const closeOnEscape = (event: KeyboardEvent) => event.key === 'Escape' && handleModal(null);

  useEffect(() => {
    window.addEventListener('keydown', closeOnEscape);
    return () => window.removeEventListener('keydown', closeOnEscape);
  }, []);

  useClickOutside({ ref: innerRef, handler: () => handleModal(null) });

  return (
    <CSSTransition
      in={!!(modalContent && modal)}
      timeout={200}
      unmountOnExit
      classNames={{
        enter: classes.enter,
        enterActive: classes.enterActive,
        exit: classes.leave,
        exitActive: classes.leaveActive,
      }}
    >
      <div style={{ position: 'absolute', zIndex: 30000 }}>
        <div className={cx(classes.wrapper, className)} {...others}>
          <div
            className={classes.inner}
            ref={innerRef}
            style={{ width: modalWidth || 600 }}
          >
            <div className={classes.content}>
              {/*{!disableCloseButton && (*/}
                <SimpleButton
                  className={classes.close}
                  component="button"
                  onClick={() => handleModal(null)}
                  type="button"
                  aria-label="Close modal"
                >
                  <X size={20} />
                </SimpleButton>
              {/*)}*/}
              {modalContent}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
