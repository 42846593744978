import React from "react";
import IconInterface from './iconInterface';

export default function QuoteEndIcon({ size = 54, ...others }: IconInterface) {
  return (
    <svg fill="none" viewBox="0 0 22 21" xmlns="http://www.w3.org/2000/svg" height={size} width={size} {...others}>
      <path
        fill="currentColor"
        d="M2.063 10.5H5.5v-3c0-1.655-1.233-3-2.75-3h-.344c-.571 0-1.031-.502-1.031-1.125v-2.25C1.375.502 1.835 0 2.406 0h.344c3.798 0 6.875 3.356 6.875 7.5v11.25c0 1.242-.924 2.25-2.063 2.25h-5.5C.925 21 0 19.992 0 18.75v-6c0-1.242.924-2.25 2.063-2.25zm12.374 0h3.438v-3c0-1.655-1.233-3-2.75-3h-.344c-.571 0-1.031-.502-1.031-1.125v-2.25C13.75.502 14.21 0 14.781 0h.344C18.923 0 22 3.356 22 7.5v11.25c0 1.242-.924 2.25-2.063 2.25h-5.5c-1.138 0-2.062-1.008-2.062-2.25v-6c0-1.242.924-2.25 2.063-2.25z" />
    </svg>
  );
}
