import React from 'react';
import { Entry } from 'contentful';
import { ButtonType, CountryType, FooterType } from '../../util/contentfulTypes';
import Footer from './Footer';

export default function FooterContentful({ footer, disablePadding, location, ...others }: {
  footer?: Entry<FooterType>,
  disablePadding?: boolean,
  location?: CountryType,
}) {
  if (!footer) {
    return null;
  }

  const countriesList = footer?.fields?.countriesList?.map((country: Entry<CountryType>) => (
    country.fields
  )) || [];

  return (
    <Footer
      {...footer?.fields}
      buttons={footer?.fields?.buttons?.map((button: Entry<ButtonType>) => (
        {
          ...button.fields,
          trackingKey: button.fields.footerTrackingKey,
          href: button.fields.href,
        }
      ))}
      logo={footer?.fields?.logo?.fields?.file?.url}
      applyNow={footer?.fields?.applyNow?.fields}
      currentCountry={countriesList.find(item => item.code === location?.code) || {
        code: 'US',
        locale: 'en-US',
        title: 'United States',
      }}
      countriesList={countriesList}
      disablePadding={disablePadding}
      {...others}
    />
  );
}
