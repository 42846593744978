import React from 'react';
import { Entry } from 'contentful';
import { ModalProvider } from '../Modal/ModalContext';
import { CountryType, LayoutType, PageType } from '../../util/contentfulTypes';
import NavigationContainer from '../NavigationContainer/NavigationContainer';
import Notification from '../Notification/Notification';
import NavigationContentful from '../Navigation/NavigationContentful';
import FooterContentful from '../Footer/FooterContentful';
import { hide } from 'cli-cursor';

export interface ImageBlockWrapperProps extends React.HTMLProps<HTMLDivElement> {
  active?: string,
  currentPage?: Entry<PageType> | null,
  layout?: Entry<LayoutType>,
  disableFooterPadding?: boolean,
  location?: CountryType,
  disableNotification?: boolean,

  setDisableNotification(): void;
}

export default function LayoutWrapper({
  active,
  currentPage,
  layout,
  children,
  disableFooterPadding,
  location,
  disableNotification,
  setDisableNotification,
}: ImageBlockWrapperProps) {
  if (!layout) {
    return <ModalProvider>{children}</ModalProvider>;
  }

  return (
    <ModalProvider>
      <NavigationContainer withHero={!!currentPage?.fields?.hero}>
        <Notification
          {...(layout?.fields?.notification?.fields || {})}
          entryKey={layout.fields.notification?.sys.id}
          disableNotification={disableNotification}
          setDisableNotification={setDisableNotification}
        />
        <NavigationContentful
          navigation={layout?.fields?.navigation}
          active={active}
          scrolled={!currentPage?.fields?.hero}
        />
      </NavigationContainer>
      <main>
        {children}
      </main>
      <FooterContentful
        disablePadding={disableFooterPadding}
        footer={layout?.fields?.footer}
        location={location}
      />
    </ModalProvider>
  );
}
