import React, { useEffect, useState } from 'react';
import cx from 'clsx';
import { useRouter } from 'next/router';
import Container from '../Container/Container';
import SimpleButton from '../SimpleButton/SimpleButton';
import Text from '../Text/Text';
import { ButtonType, CountryType } from '../../util/contentfulTypes';
import CountryButton from '../CountryButton/CountryButton';
import classes from './Footer.module.scss';

export interface FooterProps extends React.HTMLProps<HTMLDivElement> {
  className?: string,
  buttons?: ButtonType[],
  logo?: string,
  active?: '/' | '/about' | '/how-it-works' | '/stories' | '/faq' | '/application',
  slogan?: string,
  applyNow?: ButtonType,
  copyright?: string,
  currentCountry?: CountryType,
  countriesList: CountryType[],
  stayButtonLabel?: string,
  countriesTitle?: string,
  countriesDescription?: string,
  disablePadding?: boolean,
}

export default function Footer({
  className,
  buttons,
  logo,
  active,
  slogan,
  applyNow,
  copyright,
  currentCountry,
  countriesList,
  stayButtonLabel,
  countriesTitle,
  countriesDescription,
  disablePadding,
  ...others
}: FooterProps) {
  const [currentPath, setCurrentPath] = useState<string>(active || '/');
  const router = useRouter();

  const updateCountry = async ({ code, locale }: { code: string, locale: string }) => {
    const regionRequest = await fetch('/api/get-region', {
      method: 'POST',
      body: JSON.stringify({ country: code }),
    }).then(res => res.json());

    if (document) {
      document.cookie = `locale=${locale}`;
      document.cookie = `country=${code}`;
      document.cookie = `region=${regionRequest.region}`;
    }
    router.reload();
  };

  const onFooterClick = (href: string) => {
    const questionIndex = router.asPath.indexOf('?');
    if (router.asPath.slice(0, questionIndex == -1 ? router.asPath.length : questionIndex) !== href) {
      router.push({
        pathname: '/',
        query: {
          slug: href.slice(1, href.length) || '',  // update the query param
        },
      }, href, { shallow: true });
    }
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (router) {
      setCurrentPath(router.pathname || '');
    } else {
      if (active) {
        setCurrentPath(active);
      }
    }
  }, [active, router]);

  return (
    <footer
      className={cx(classes.footerContainer, className, { [classes.disablePadding]: disablePadding })} {...others}>
      <div className={classes.footer}>
        <Container size="xl" className={classes.countryButtonContainer}>
          <Container size="large" className={classes.content}>
            <div className={classes.logoColumn}>
              <SimpleButton className={classes.logoButton} href={'/'} component="a">
                <div className={classes.logo}>
                  <img src={logo} alt="logo" />
                </div>
              </SimpleButton>
              <div className={classes.logoSubtext}>
                {slogan && <Text className={classes.slogan}>{slogan}</Text>}
                {applyNow &&
                <SimpleButton className={classes.apply} {...applyNow} ><span>{applyNow.children}</span></SimpleButton>}
              </div>
            </div>
            <div className={classes.buttonsColumn}>
              <div className={classes.menuButtons}>
                {buttons?.map((button, index) => (
                  <SimpleButton
                    key={index}
                    component="button"
                    className={cx(classes.menuButton, { [classes.active]: currentPath === button.href })}
                    onClick={(e) => {
                      e?.preventDefault();
                      onFooterClick(button.href);
                    }}
                    {...button}
                  />
                ))}
              </div>
              {copyright && (
                <Text className={classes.copyright}>
                  {copyright.replace('%year%', (new Date().getFullYear()).toString())}
                </Text>
              )}
            </div>
          </Container>
          <CountryButton
            className={classes.countrySelector}
            currentCountry={currentCountry}
            countriesList={countriesList}
            onSelectCountry={updateCountry}
            title={countriesTitle}
            description={countriesDescription}
            stayButtonLabel={stayButtonLabel}
          />
        </Container>
      </div>
    </footer>
  );
}
