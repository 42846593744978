import React, { useEffect, useState } from 'react';
import cx from 'clsx';
import { decodeHTMLEntities } from '../../util/fixRichText';
import classes from './Text.module.scss';

export interface TextProps<T = {}> extends React.HTMLProps<T> {
  className?: string;
  children?: React.ReactNode | string;
  component?: React.ComponentType<T> | React.ElementType;
  style?: Record<string, any>;
  bold?: boolean;
  error?: boolean;
  subtitle?: boolean,
}

const addIconsToBlockquote = (html: string) => {
  return html.replace(
    /<blockquote(.*?)>(.*?)<\/blockquote>/gm,
    `<blockquote$1><span class="${classes.blockquoteContent}">$2</span></blockquote>`);
};

export default function Text<T = 'p'>({
  className,
  component: Element = 'p',
  children = null,
  style,
  subtitle,
  bold,
  error,
  ...others
}: TextProps<T>) {
  const [fixedValue, setFixedValue] = useState(typeof children === 'string' ? children : '');
  const isTitle = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(Element as string);
  const isHtml = typeof children === 'string' && /<\/?[a-z][\s\S]*>/i.test(children);

  const updateValue = () => {
    if (document && typeof children === 'string' && !isHtml) {
      setFixedValue(decodeHTMLEntities(children));
    }
  };

  useEffect(() => {
    updateValue();
  }, []);

  useEffect(() => {
    updateValue();
  }, [children]);

  return isHtml ? (
    //@ts-ignore
    <div
      className={cx(classes.text, {
        [classes.bold]: bold,
        // [classes.error]: error,
        [classes.withChildren]: isHtml,
        [classes.subtitle]: subtitle,
      }, className)}
      style={style}
      {...others}
      dangerouslySetInnerHTML={{ __html: addIconsToBlockquote(decodeURI(fixedValue)) }}
    />
  ) : (
    <Element
      className={cx(classes.text, {
        [classes.bold]: bold,
        [classes[Element as string]]: isTitle,
        [classes.error]: error,
        [classes.subtitle]: subtitle,
      }, className)}
      style={style}
      {...others}>
      {typeof children === 'string' ? fixedValue : children}
    </Element>
  );
}
