import React from 'react';
import { useEffect } from 'react';

const DEFAULT_HANDLERS = ['mousedown', 'touchstart'];

interface ClickOutsideProps {
  ref: React.RefObject<any>,
  handlers?: string[],
  handler: Function,
}

export default function useOnClickOutside({ ref, handler, handlers = DEFAULT_HANDLERS }: ClickOutsideProps) {
  const isBrowser = typeof document !== 'undefined';

  useEffect(() => {
    const listener = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handler(event);
      }
    };

    isBrowser && handlers.forEach(fn => document.addEventListener(fn, listener));

    return () => {
      isBrowser && handlers.forEach(fn => document.removeEventListener(fn, listener));
    };
  }, [ref, handler]);
}
