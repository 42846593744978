export default function initMiddleware(middleware: any) {
  //@ts-ignore
  return (req, res) =>
    new Promise((resolve, reject) => {
      middleware(req, res, (result: any ) => {
        if (result instanceof Error) {
          return reject(result);
        }
        return resolve({ result, body: req?.body });
      });
    });
}
