import type { RegionsType } from './types';
import { getEntries } from '../contentful/client';

export default async function getRegion(countryCode?: string | null) {
  if (!countryCode) {
    return null;
  }
  const regions = await getEntries('regionsMap').then(res => res?.[0]?.fields?.regions);

  if (!regions) {
    return null;
  }

  return Object.keys(regions)
    .find((key: string) => regions[key].find((country: string) => country === countryCode.toUpperCase())) as keyof RegionsType;
}
