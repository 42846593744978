import React from 'react';
import cx from 'clsx';
import classes from './Container.module.scss';

export interface ContainerProps<T> {
  className?: string;
  component?: React.ComponentType<T> | React.ElementType,

  /** Container size */
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xl' | number;

  /** Fluid container has width=100% */
  fluid?: boolean;
  children: React.ReactNode
}

export default function Container<T = 'div'>({
  className,
  size = 'xl',
  component: Element = 'div',
  fluid = false,
  ...others
}: ContainerProps<T>) {
  return (
    <Element
      className={cx(classes.wrapper, classes[size], { [classes.fluid]: fluid }, className)}
      {...others}
    />
  );
}
