import React from 'react';

export default function GtmBody({ gtmTag }: { gtmTag?: string }) {
  if (!gtmTag) {
    return null;
  }

  return (
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmTag}"
height="0" width="0" style="display:none;visibility:hidden"/>`,
      }}
    />
  );
}
