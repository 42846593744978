import React from 'react';
import Bugsnag, { Client } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

let BugsnagClient: Client | null = null;

export function bugsnagStart(api_key?: string) {
  if (!BugsnagClient && api_key) {
    BugsnagClient = Bugsnag.start({
      apiKey: api_key || '',
      plugins: [new BugsnagPluginReact(React)],
    });
  }
}

export function getErrorBoundary() {
  return BugsnagClient ? BugsnagClient.getPlugin("react")?.createErrorBoundary() : null
}

export default BugsnagClient;
