import React, { useRef, useState } from 'react';
import cx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import { X } from 'react-feather';
import Text from '../Text/Text';
import Container from '../Container/Container';
import { ButtonType } from '../../util/contentfulTypes';
import Button from '../Button/Button';
import classes from './CookiesNotification.module.scss';

export interface CookiesNotificationProps extends React.HTMLProps<HTMLDivElement> {
  description?: string,
  acceptButton?: ButtonType,
  buttons?: ButtonType[],
  cookiesAccepted?: boolean,
  storybookMode?: boolean,
}

export default function CookiesNotification({
  className,
  description,
  acceptButton,
  buttons,
  cookiesAccepted = false,
  storybookMode = false,
  ...others
}: CookiesNotificationProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [disabled, setDisabled] = useState(false);

  async function setAcceptCookies() {
    await fetch('/api/accept-cookies', {
      method: 'POST',
    });
  }

  const acceptCookies = () => {
    if (!storybookMode) {
      setAcceptCookies();
    }
    setDisabled(true);
  };

  if (cookiesAccepted) {
    return null;
  }

  return (
    <CSSTransition
      in={!disabled}
      timeout={200}
      classNames={classes}
      unmountOnExit
    >
      <div className={cx(classes.cookiesNotification, className)} ref={ref} {...others}>
        <Container size="large" className={classes.container}>
          {description && (
            <div className={classes.descriptionContainer}>
              <Text className={classes.content}>{description}</Text>
              <button
                type="button"
                onClick={() => setDisabled(true)}
                className={cx(classes.closeButton, classes.mobileClose)}
                aria-label="close cookies notification"
              >
                <X size={24} className={classes.closeIcon} />
              </button>
            </div>
          )}
          {(acceptButton || buttons) && (
            <div className={classes.buttons}>
              {acceptButton && (
                <Button
                  {...acceptButton}
                  component="button"
                  onClick={acceptCookies}
                />
              )}
              {buttons && buttons.map((button: ButtonType, key: number) => (
                <Button {...button} key={key} />
              ))}
            </div>
          )}
          <button
            type="button"
            onClick={() => setDisabled(true)}
            className={cx(classes.closeButton, classes.desktopClose)}
            aria-label="close cookies notification"
          >
            <X size={24} className={classes.closeIcon} />
          </button>
        </Container>
      </div>
    </CSSTransition>
  );
}
