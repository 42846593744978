import React from 'react';
import IconInterface from './iconInterface';

export default function PlayIcon({ size = 54, ...others }: IconInterface) {
  return (
    <svg fill="none" viewBox="0 0 54 60" xmlns="http://www.w3.org/2000/svg" height={size} width={size} {...others}>
      <path
        d="M51.157 25.155L8.727.77C5.28-1.21 0 .71 0 5.61v48.759c0 4.394 4.906 7.043 8.727 4.84l42.43-24.374c3.785-2.168 3.797-7.512 0-9.68z"
        fill="currentColor"
      />
    </svg>
  );
}
