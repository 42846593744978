import React from 'react';
import IconInterface from './iconInterface';

export default function QuoteStartIcon({ size = 48, ...others }: IconInterface) {
  return (
    <svg fill="none" viewBox="0 0 48 34"  xmlns="http://www.w3.org/2000/svg" width={size} {...others}>
      <path
        fill="currentColor"
        d="M43.5 17H36V12.1429C36 9.46384 38.6906 7.28571 42 7.28571H42.75C43.9969 7.28571 45 6.47366 45 5.46429V1.82143C45 0.812054 43.9969 0 42.75 0H42C33.7125 0 27 5.43393 27 12.1429V30.3571C27 32.3683 29.0156 34 31.5 34H43.5C45.9844 34 48 32.3683 48 30.3571V20.6429C48 18.6317 45.9844 17 43.5 17ZM16.5 17H9V12.1429C9 9.46384 11.6906 7.28571 15 7.28571H15.75C16.9969 7.28571 18 6.47366 18 5.46429V1.82143C18 0.812054 16.9969 0 15.75 0H15C6.7125 0 0 5.43393 0 12.1429V30.3571C0 32.3683 2.01562 34 4.5 34H16.5C18.9844 34 21 32.3683 21 30.3571V20.6429C21 18.6317 18.9844 17 16.5 17Z"
      />
    </svg>
  );
}
