import React, { CSSProperties } from 'react';
import Link from 'next/link';
import cx from 'clsx';
import iconMap from '../icons/iconMap';
import { Asset } from 'contentful';
import classes from './Button.module.scss';

export interface ButtonProps<T> extends Omit<React.HTMLProps<HTMLButtonElement>, 'style'> {
  type?: 'button' | 'submit' | 'reset';
  theme?: 'blue';
  component?: React.ElementType<any>,
  secondary?: boolean;
  darkBackground?: boolean;
  style?: CSSProperties;
  ariaLabel?: string;
  href?: string;
  disabled?: boolean;
  icon?: keyof typeof iconMap,
  iconRight?: boolean,
  trackingKey?: string,
  withVideo?: boolean,
  videoUrl?: string,
  withFileLink?: boolean,
  file?: Asset,
  openInNewTab?: boolean,

  onClick?(arg0?: React.MouseEvent | React.TouchEvent): void,

  showVideo?(arg0?: string): void,
}

export default function Button<T= 'a'>({
  className,
  component = 'a',
  href,
  onClick,
  theme = 'blue',
  darkBackground = false,
  secondary = false,
  ariaLabel,
  style,
  children,
  type,
  disabled,
  icon,
  iconRight,
  trackingKey,
  withVideo,
  videoUrl,
  showVideo,
  withFileLink,
  file,
  openInNewTab,
  ...others
}: ButtonProps<T>) {
  const Component = component || (withVideo && videoUrl ? 'button' : 'a') as React.ElementType;
  const Icon = icon && Object.keys(iconMap).includes(icon) ? iconMap[icon] : null;

  const fixLink = (link: string) => link && link.slice(0, 2) === '//' ? `https:${link}` : link;

  return Component === 'a' ? (
    <Link
      href={(withFileLink && file?.fields?.file?.url ? fixLink(file.fields?.file?.url) : href) || '/'}
      as={(withFileLink && file?.fields?.file?.url ? fixLink(file.fields?.file?.url) : href) || '/'}
      shallow
    >
      {/*@ts-ignore*/}
      <Component
        /*@ts-ignore*/
        data-button
        role="button"
        onClick={(e: React.MouseEvent | React.TouchEvent) => {
          if (onClick && !disabled) {
            onClick(e);
          }
        }}
        className={cx(
          classes.button,
          classes[theme],
          {
            [classes.secondary]: secondary,
            [classes.dark]: darkBackground,
            [classes.disabled]: disabled,
            [classes.iconRight]: Icon && iconRight,
          },
          className)}
        aria-label={ariaLabel}
        style={typeof style === 'string' ? JSON.parse(style) : style}
        data-tracking-key={trackingKey}
        target={openInNewTab ? '_blank' : undefined}
        {...others}
      >
        {/*@ts-ignore*/}
        {Icon && <Icon size={16} className={classes.icon} />}
        {children}
      </Component>
    </Link>
  ) : (
    <Component
      data-button
      role="button"
      className={cx(
        classes.button,
        classes[theme],
        {
          [classes.secondary]: secondary,
          [classes.dark]: darkBackground,
          [classes.disabled]: disabled,
          [classes.iconRight]: Icon && iconRight,
        },
        className)}
      type={type || 'button'}
      disabled={disabled}
      aria-label={ariaLabel}
      style={typeof style === 'string' ? JSON.parse(style) : style}
      onClick={(e: React.MouseEvent | React.TouchEvent) => {
        if (!disabled) {
          if (withVideo && videoUrl && showVideo) {
            showVideo(videoUrl);
          } else {
            if (onClick) {
              onClick(e);
            }
          }
        }
      }}
      data-tracking-key={trackingKey}
      {...others}
    >
      {/*@ts-ignore*/}
      {Icon && <Icon size={16} className={classes.icon} />}
      {children}
    </Component>
  );
}
