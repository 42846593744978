import { findCountryLocales } from 'iso-lang-codes';
import getCountry from './getCountry';
import { RegionsType, FallbackType, GeolocationRequestInterface, GeolocationType } from './types';
import { getLocales } from '../contentful/client';
import { Locale } from 'contentful';
import getRegion from './getRegion';

const regionFallback = {
  apac: 'en-US',
  emea: 'en-US',
  latam: 'es-US',
  na: 'en-US',
} as FallbackType;

const getActiveLocales = (
  countryLocales: string[],
  fallbackLocales: FallbackType,
  region?: keyof RegionsType | null,
  locales?: Locale[],
) => (
  locales?.reduce((previous, current: Locale) => {
    if (countryLocales.find((countryLocale: string) => countryLocale === current.code)) {
      return [...previous, current.code];
    }
    const fall = fallbackLocales[(region?.toLocaleLowerCase() || 'na') as keyof typeof regionFallback];
    return [...previous, fall];
  }, [] as string[])
);

export default async function getGeolocation({ res, req, cookies, fallbacks }: GeolocationRequestInterface) {
  const fallbackLocales = fallbacks || regionFallback;
  const resultData = {
    locale: cookies?.locale,
    userLocale: cookies?.userLocale,
    countryLocale: cookies?.countryLocale,
    country: cookies?.country,
    region: cookies?.region,
    update: cookies?.update,
  } as GeolocationType;

  if (!req || !res) {
    return resultData;
  }

  if (res.statusCode === 200) {
    const locales: Locale[] | undefined = await getLocales();
    const ipCountry = await getCountry();

    if (resultData.locale && resultData.country && resultData.region) {
      if (locales?.find(loc => loc.code === resultData.locale)) {
        //TODO: check for changed location
        // if (resultData.country === ipCountry) {
        return resultData;
        // }
      }
    }

    let region: keyof RegionsType | null = null;
    if (req && res) {
      region = await getRegion(resultData.country || ipCountry);
    } else {
      region = await fetch('/api/get-region', {
        method: 'POST',
        body: JSON.stringify({ country: resultData.country || ipCountry }),
      }).then(res => res.json());
    }

    const countryLocales: string[] = ipCountry ? findCountryLocales(ipCountry) : [];

    const foundLocales = getActiveLocales(countryLocales, fallbackLocales, region, locales);
    const uniqueLocales = [...new Set(foundLocales)];

    if (uniqueLocales && uniqueLocales?.length > 0) {
      resultData.locale = locales?.find(loc => loc.code === uniqueLocales[0])?.code || (
        region ? regionFallback[(region || 'na') as keyof typeof regionFallback] : 'en-US'
      ) || 'en-US';
      resultData.countryLocale = uniqueLocales[0];
    }

    resultData.country = resultData.country || ipCountry;
    resultData.region = region;
    resultData.userLocale = req?.headers['accept-language']?.slice(0, 5);
  }

  return resultData;
}
