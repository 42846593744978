export default async function getCountry(ip?: string) {
  const key = process.env.GEOLOCATION_API_KEY;
  return await fetch(`https://extreme-ip-lookup.com/json/${ip || ''}${key ? `?key=${key}` : ''}`)
    .then(res => res.json())
    .then(res => res.countryCode)
    .catch((err) => {
      console.log(err);
      return null;
    });
}
