import React from 'react';
import classes from './BurgerIcon.module.scss';
import cx from 'clsx';

export default function BurgerIcon({ opened = false }: { opened: boolean }) {
  return (
    <div className={cx(classes.burger, { [classes.open]: opened })}>
      <span />
      <span />
      <span />
    </div>
  );
}
