import React, { useRef, useState } from 'react';
import cx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import { X } from 'react-feather';
import Text from '../Text/Text';
import Container from '../Container/Container';
import classes from './Notification.module.scss';

export interface NotificationProps extends React.HTMLProps<HTMLDivElement> {
  withDisable?: boolean,
  entryKey?: string,
  disableNotification?: boolean,
  ariaLabel?: string,

  setDisableNotification(): void;
}

export default function Notification({
  className,
  children,
  withDisable = false,
  entryKey,
  disableNotification,
  setDisableNotification,
  ariaLabel,
  ...others
}: NotificationProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [disabled, setDisabled] = useState(disableNotification || false);

  if (!children) {
    return null;
  }

  const toggleNotification = () => {
    if (document) {
      if (!document.location.pathname.includes('storybook-previews')) {
        document.cookie = `adapt_hide_notification_${entryKey}=true`;
      }
    }
    setDisabled(true);
    setDisableNotification();
  };

  return (
    <CSSTransition
      in={!disabled}
      timeout={200}
      classNames={classes}
      unmountOnExit
    >
      <div className={cx(classes.notification, className)} ref={ref} {...others}>
        <Container size="small" className={classes.container}>
          <Text className={classes.content}>{children}</Text>
          {withDisable && (
            <button
              type="button"
              onClick={toggleNotification}
              className={classes.closeButton}
              aria-label={ariaLabel}
            >
              <X size={24} className={classes.closeIcon} />
            </button>
          )}
        </Container>
      </div>
    </CSSTransition>
  );
}
