import React, { useContext } from 'react';
import Link from 'next/link';
import cx from 'clsx';
import { CountryType } from '../../util/contentfulTypes';
import Text from '../Text/Text';
import Button from '../Button/Button';
import { ModalContext } from '../Modal/ModalContext';
import SimpleButton from '../SimpleButton/SimpleButton';
import classes from './CountryButton.module.scss';

export interface CountryButtonProps extends React.HTMLProps<HTMLDivElement> {
  currentCountry?: CountryType,
  countriesList?: CountryType[],
  title?: string,
  stayButtonLabel?: string,
  description?: string,

  onSelectCountry(arg0: CountryType): void,
}

export default function CountryButton({
  className,
  currentCountry,
  countriesList,
  title,
  stayButtonLabel,
  description,
  onSelectCountry,
  ...others
}: CountryButtonProps) {
  const { handleModal } = useContext(ModalContext);

  if (!countriesList || !currentCountry) {
    return null;
  }

  const modalContent = () => (
    <div className={classes.modalContent}>
      <div className={classes.textContent}>
        {title && (
          <Text
            className={cx(classes.title, { [classes.fontStyle]: !/<\/?[a-z][\s\S]*>/i.test(title) })}
          >
            {title}
          </Text>
        )}
        <Button component="button" type="button" onClick={() => handleModal(null)}>
          {stayButtonLabel}
        </Button>
        {description && <Text className={classes.description}>{description}</Text>}
        <div className={classes.countries}>
          {countriesList.map((item, index) => (
            item.url ? (
              <Link key={index} passHref href={item.url}>
                <SimpleButton
                  component="a"
                  className={cx(classes.button, classes.flagButton)}
                  target="_blank"
                  onClick={() => {
                    handleModal(null);
                  }}
                >
                  <span>{item.title}</span>
                </SimpleButton>
              </Link>
            ) : (
              <SimpleButton
                key={index}
                className={cx(classes.button, classes.flagButton)}
                onClick={() => {
                  onSelectCountry(item);
                  handleModal(null);
                }}
              >
                <span>{item.title}</span>
              </SimpleButton>
            )
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className={cx(classes.countryButtonContainer, className)} {...others}>
      <SimpleButton
        onClick={(event) => {
          event?.preventDefault();
          handleModal(modalContent(), 1100)
        }}
        className={cx(classes.button, classes.countryButton)}
      >
        <span>{currentCountry.title}</span>
      </SimpleButton>
    </div>
  );
}
