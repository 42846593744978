import { createClient, Entry, EntryCollection, Locale, LocaleCollection, TagCollection } from 'contentful';


const check = () => process.env.CONTENTFUL_DELIVERY_TOKEN &&
  process.env.CONTENTFUL_SPACE_ID && process.env.CONTENTFUL_ENV;

const client = check() && createClient({
  space: process.env.CONTENTFUL_SPACE_ID || '',
  accessToken: (process.env.CONTENTFUL_HOST === 'preview.contentful.com' ?
    process.env.CONTENTFUL_PREVIEW_TOKEN : process.env.CONTENTFUL_DELIVERY_TOKEN) || '',
  environment: process.env.CONTENTFUL_ENV,
  host: process.env.CONTENTFUL_HOST || 'cdn.contentful.com',
  resolveLinks: true,
}) || null;

export async function getEntries(content_type: string, locale?: string | null) {
  return client?.getEntries({ content_type, include: 10, locale: locale || 'en-US' })
    .then((entries: EntryCollection<any>) => entries.items)
    .catch(() => []);
}

export async function getLocales() {
  return client?.getLocales()
    .then((locales: LocaleCollection) => locales.items)
    .catch(() => [] as Locale[]);
}

export async function getTags() {
  return client?.getTags()
    .then((tags: TagCollection) => tags.items)
    .catch(() => []);
}

export async function getEntry(id: string, locale?: string | null) {
  return client?.getEntries({ 'sys.id': id, include: 10, locale: locale || 'en-US' })
    .then((entries: EntryCollection<any>) => entries.items[0])
    .catch((err: Error) => {
      const parsed = JSON.parse(err.message);
      return {
        status: parsed.status,
        message: parsed.message,
        fields: null,
      };
    });
}

export default client;
